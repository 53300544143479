import React from 'react'

import cn from 'classnames'

import { SiteContext } from 'context/site-context'

import About from 'components/sections/About'
import AdditionalServices from 'components/sections/AdditionalServices'
import Footer from 'components/sections/Footer'
import Header from 'components/sections/Header'
import HeroEmergency from 'components/sections/HeroEmergency'
import HeroLanding from 'components/sections/HeroLanding'
import HeroServices from 'components/sections/HeroServices'
import HowWeCanHelp from 'components/sections/HowWeCanHelp'
import PriceGuide from 'components/atoms/PriceGuide'
import Services from 'components/sections/Services'
import SimpleCTA from 'components/sections/SimpleCTA'
import Why from 'components/sections/Why'

import Modal from 'components/atoms/Modal'

import './index.scss'

const priceList = [
  { label: 'Service Fee	',  category: 'Minimum Charge (Included As Part The Final Service Charge)	', price: '$29 (fixed)' },
  { label: 'All Lockout Services',  category: 'Commercial, Residential & Automotive (Car Lockout)', price: '$48 - $230' },
  { label: 'Locks Re-Key', category: 'Commercial & Residential', price: '$48 - $300' },
  { label: 'Lock Repair', category: 'Commercial & Residential', price: '$48 - $180' },
  { label: 'Key Extraction', category: 'Commercial & Residential', price: '$48 - $130' },
  { label: 'Lock Change', category: 'Residential, Commercial - For Basic Locks', price: '$54 - $230' },
  { label: 'New Lock Installation', category: '(Door without a hole for a lock)', price: '$114 - $230' },
  { label: 'Unlock Safe', category: '(Damage may occur to the safe)', price: '$114 - $330' },
  { label: 'New Vehicle Key', category: 'Car, Truck', price: '$129 - $330' },
  { label: 'Ignition Change', category: 'Any Vehicle', price: '$129 - $330' },
  { label: 'Locks Change or Rekey', category: 'Any Vehicle', price: '$129	-	$330' },
  { label: 'Program Transponder', category: '(Key with a chip)', price: '$129 - $230' },
  { label: 'Key Extraction', category: 'Automotive', price: '$129 - $230' },
  { label: 'Lock Change', category: 'Push bars / Combination Lock / High Security Locks', price: '$149	-	$530' },
  { label: 'Access Control System / Buzzer System', price: '$379 - $1,230' },
]

const Landing = () => {
  const [ site ] = React.useContext(SiteContext)

  const [ state, setState ] = React.useState({
    showHeader: window.scrollY > site.minHeaderScrollDistance,
    animate: true,
  })
  React.useEffect(() => {
    const updateHeaderState = () => {
      setState({
        ...state,
        showHeader: window.scrollY > site.minHeaderScrollDistance,
      })
    }
    window.addEventListener('scroll', updateHeaderState)
    return () => {
      window.removeEventListener('scroll', updateHeaderState)
    }
  }, [state, site.minHeaderScrollDistance])

  return <div className={cn('page-wrapper animate', {isLoadingSiteData: site.isLoadingSiteData})}>
    <Header showHeader={state.showHeader} animate={state.animate} isEmergency={false} />
    <div className={cn('non-emergency-wrapper', {'override-show': site.viewOverride === 'non-emergency', 'override-hide': site.viewOverride === 'emergency'})}>
      <HeroLanding />
      <HeroServices />
      <About />
      <Why />
      <HowWeCanHelp />
    </div>
    <div className={cn('emergency-wrapper', {'override-show': site.viewOverride === 'emergency', 'override-hide': site.viewOverride === 'non-emergency'})}>
      <HeroEmergency />
      <div className="price-guide-wrapper">
        <PriceGuide priceList={priceList} />
      </div>
      <About />
      <Services />
      <AdditionalServices />
    </div>
    <SimpleCTA />
    <Footer />
    <Modal />
  </div>
}

export default Landing
