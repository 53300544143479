import React from 'react'

import { SiteContext } from 'context/site-context'

import PhoneCTAButton from 'components/atoms/PhoneCTAButton'

import { ReactComponent as CarIcon } from 'svgs/car.svg'
import { ReactComponent as HomeIcon } from 'svgs/home.svg'
import { ReactComponent as KeyIcon } from 'svgs/key.svg'

import BackgroundImage from 'images/original-hero.png'

import Logo from 'images/logo.png'

import './index.scss'

const HeroEmergency = () => {
  const [ site, setSite  ] = React.useContext(SiteContext)

  return (
    <div className="hero-emergency-container" style={{
        background: `linear-gradient( rgba(44, 43, 91, .2), rgba(2, 2, 13, .45) ), url(${BackgroundImage}) center center no-repeat`,
        backgroundSize: 'cover',
      }}>
      <div className="container">
        <div className="logo-white-container"><span onClick={ () => setSite({ ...site, viewOverride: 'non-emergency'}) }><img src={Logo} alt="Locksmith Directory" /></span></div>
        <div className="actions-container">
          <PhoneCTAButton delegate="Header" />
          <span
            className="button-trigger alt toggle-trigger"
            onClick={ () => {
              setSite({ ...site, viewOverride: 'non-emergency'})
              window.scrollTo(0, 0)
            } }>Non-Emergency<br/><span className="desktop">Lockout </span>Services</span>
        </div>
        <h1>24-hour Locksmith Dispatch</h1>
        <h2>Fast & Reliable</h2>
        <div className="buttons-container">
          <a className="button-trigger" href={`tel:${site.phone}`} data-delegate="Hero"><CarIcon />Call For Car Lockout</a>
          <a className="button-trigger" href={`tel:${site.phone}`} data-delegate="Hero"><HomeIcon />Call For Emergency Lockout</a>
          <a className="button-trigger" href={`tel:${site.phone}`} data-delegate="Hero"><KeyIcon />Call For Car Key Made</a>
        </div>
      </div>
    </div>
  )
}

export default HeroEmergency
