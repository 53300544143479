import React from 'react'

import { SiteContext } from 'context/site-context'

import { NavLink } from 'react-router-dom'

import PriceGuide from 'components/atoms/PriceGuide'

import ResidentialImage from 'images/residential-locksmith-services-hero.jpg'
import CommercialImage from 'images/commercial-locksmith-services-hero.jpg'
import AutomotiveImage from 'images/automotive-locksmith-services-hero.jpg'
import NewHomeImage from 'images/newhome-locksmith-services-hero.jpg'

import './index.scss'

const priceList = [
  { label: 'Service Fee	',  category: 'Minimum Charge (Included As Part The Final Service Charge)	', price: '$29 (fixed)' },
  { label: 'All Lockout Services',  category: 'Commercial, Residential & Automotive (Car Lockout)', price: '48 - $230' },
  { label: 'Locks Re-Key', category: 'Commercial & Residential', price: '$48 - $300' },
  { label: 'Lock Repair', category: 'Commercial & Residential', price: '$48 - $180' },
  { label: 'Key Extraction', category: 'Commercial & Residential', price: '$48 - $130' },
  { label: 'Lock Change', category: 'Residential, Commercial - For Basic Locks', price: '$54 - $230' },
  { label: 'New Lock Installation', category: '(Door without a hole for a lock)', price: '$114 - $230' },
  { label: 'Unlock Safe', category: '(Damage may occur to the safe)', price: '$114 - $330' },
  { label: 'New Vehicle Key', category: 'Car, Truck', price: '$129 - $330' },
  { label: 'Ignition Change', category: 'Any Vehicle', price: '$129 - $330' },
  { label: 'Locks Change or Rekey', category: 'Any Vehicle', price: '$129	-	$330' },
  { label: 'Program Transponder', category: '(Key with a chip)', price: '$129 - $230' },
  { label: 'Key Extraction', category: 'Automotive', price: '$129 - $230' },
  { label: 'Lock Change', category: 'Push bars / Combination Lock / High Security Locks', price: '$149	-	$530' },
  { label: 'Access Control System / Buzzer System', price: '$379 - $1,230' },
]

const HeroServices = () => {
  const [site, setSite ] = React.useContext(SiteContext)

  return (
    <div className="hero-services-container">
      <div className="services-connect-container">
        <h3>Services We'll Connect You To</h3>
        <div className="panels-container">
          <NavLink to={`${process.env.PUBLIC_URL}/ld-residential`} className="panel" data-tracking-data={JSON.stringify({ label: 'Residential Locksmiths' }) } onClick={ () => setSite({ ...site, showHeader: false }) }>
            <h4 style={{
                background: `linear-gradient( rgba(44, 43, 91, .6), rgba(2, 2, 13, .85) ), url(${ResidentialImage}) center center no-repeat`,
                backgroundSize: 'cover',
              }}>Residential<br />Locksmiths</h4>
            <p>Having problems with your home locks or security system? Upgrade your locks, install smart door systems, and more!</p>
            <span>Read More ></span>
          </NavLink>
          <NavLink to={`${process.env.PUBLIC_URL}/ld-commercial`} className="panel" data-tracking-data={JSON.stringify({ label: 'Commercial Locksmiths' }) } onClick={ () => setSite({ ...site, showHeader: false }) }>
            <h4 style={{
                background: `linear-gradient( rgba(44, 43, 91, .6), rgba(2, 2, 13, .85) ), url(${CommercialImage}) center center no-repeat`,
                backgroundSize: 'cover',
              }}>Commercial<br />Locksmiths</h4>
            <p>Protect your business with commercial grade security. Biometric and other high security lock installations available.</p>
            <span>Read More ></span>
          </NavLink>
          <NavLink to={`${process.env.PUBLIC_URL}/ld-automotive`} className="panel" data-tracking-data={JSON.stringify({ label: 'Automotive Locksmiths' }) } onClick={ () => setSite({ ...site, showHeader: false }) }>
            <h4 style={{
                background: `linear-gradient( rgba(44, 43, 91, .6), rgba(2, 2, 13, .85) ), url(${AutomotiveImage}) center center no-repeat`,
                backgroundSize: 'cover',
              }}>Automotive<br />Locksmiths</h4>
            <p>Keep your car's locking mechanisms up to date. Our locksmiths service your Keyless-Ignition Vehicles and Car Key Remotes</p>
            <span>Read More ></span>
          </NavLink>
          <NavLink to={`${process.env.PUBLIC_URL}/ld-new-home`} className="panel" data-tracking-data={JSON.stringify({ label: 'New Home Owner Services' }) } onClick={ () => setSite({ ...site, showHeader: false }) }>
            <h4 style={{
                background: `linear-gradient( rgba(44, 43, 91, .6), rgba(2, 2, 13, .85) ), url(${NewHomeImage}) center center no-repeat`,
                backgroundSize: 'cover',
              }}>New Home<br />Owner Services</h4>
            <p>Sleep sound in your new home. Rekeying and Security Installation Services safeguard you and your loved ones</p>
            <span>Read More ></span>
          </NavLink>
        </div>
      </div>
      <div className="price-guide-wrapper">
        <PriceGuide priceList={priceList} type="narrow" />
      </div>
    </div>
  )
}

export default HeroServices
