import React from 'react'
import PropTypes from 'prop-types'

import { getTrackingData } from 'helpers'

import { SiteContext } from 'context/site-context'

import PhoneIcon from 'images/247phone.png'

import './index.scss'

const PhoneCTAButton = props => {
  const [ state ] = React.useContext(SiteContext)

  return <a className="button-trigger phone-trigger-container" href={`tel:${state.phone}`} data-delegate={props.delegate} data-tracking-data={getTrackingData()}>
    <img src={PhoneIcon} alt="Call us 24/7" />
    <label>Call us 24/7</label>
    <div>{state.phone}</div>
  </a>
}

PhoneCTAButton.propTypes = {
  delegate: PropTypes.string,
}

export default PhoneCTAButton
