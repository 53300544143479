const formatPhoneNumber = str => {
  const cleaned = ('' + str).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if(match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return
}

const getGeoFromLocalStorage = () => {
  let geoInfo = null
  try {
    geoInfo = JSON.parse(localStorage.getItem('geoInfo'))
  } catch (e) { }
  return geoInfo
}

const getSessionId = () => {
  if(!sessionStorage['session_id']){
    const crypto = window.crypto || window.msCrypto
    sessionStorage.setItem('session_id', new Date().getTime() + '-' + crypto.getRandomValues(new Uint8Array(8)).join(''));
  }
  return sessionStorage['session_id'];
}

const getTrackingData = () => {
  return JSON.stringify({
    page: window.location.href,
  })
}

const setCategoryData = data => {
  // Update the DOM (for tracking).
  document.getElementById('root').setAttribute('data-category-type', data.category_type)
  document.getElementById('root').setAttribute('data-parent-category', data.parent_category_name)
  document.getElementById('root').setAttribute('data-parent-category-id', data.parent_category_id)
  document.getElementById('root').setAttribute('data-service-category-id', data.category_id)
  document.getElementById('root').setAttribute('data-service-category-name', data.category_name)

  // Update localStorage.
  localStorage.setItem('category', JSON.stringify(data))
}

export {
  formatPhoneNumber,
  getGeoFromLocalStorage,
  getSessionId,
  getTrackingData,
  setCategoryData,
}
