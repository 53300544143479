import React from 'react'

import NavPanel from 'components/atoms/NavPanel'

import './index.scss'

const AdditionalServices = () => {
  return (
    <div className="additional-services-container">
      <div className="container">
        <h3>Additional Services<span className="block"></span></h3>
        <p>
          Need a lock repaired or replaced?  Check out the other services provided by our master locksmiths
        </p>
        <NavPanel />
      </div>
    </div>
  )
}

export default AdditionalServices
