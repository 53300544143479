import React from 'react'

import {
  formatPhoneNumber,
  getGeoFromLocalStorage,
  getSessionId,
  setCategoryData,
} from 'helpers'

import metadata from './metadata'

export const SiteContext = React.createContext()

export const SiteContextProvider = props => {
  const [ state, setState ] = React.useState({
    ...metadata,
    city: 'Your Area',
    minHeaderScrollDistance: 100,
    viewOverride: '',
    modalShown: false,
    showHeader: false,
    phone: '',
    isLoadingSiteData: true,
  })

  const geoInfo = getGeoFromLocalStorage()
  let categoryData = {}

  React.useEffect( async () => {
    const updateContext = geoInfo => {
      // Finally, update the context object.
      setState( prevState => ({
        ...prevState,
        ...metadata,
        city: geoInfo ? geoInfo.city : 'Your Area',
        zip_code: geoInfo ? geoInfo.zip_code : prevState.zip_code,
        isLoadingSiteData: false,
      }))
    }

    const updateVisitData = () => {
      // Set up a looper that will fire the follow up call to the PTM API once we've got all of the 3rd party ids.
      let tries = 0
      const maxTries = 10
      const interval = setInterval( () => {
        tries+= 1
        if(window.analytics && window.ga && window.ga.getAll || tries > maxTries){
          fetch('https://twilioproxy.elocal.com/api/front_end/update_visit_data', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              anonymous_id: window.analytics?.user ? analytics.user().anonymousId() : null,
              ga_client_id: window.ga ? window.ga.getAll()[0].get('clientId') : null,
              session_id: getSessionId(),
            })
          })
          .catch( err => {} )
          clearInterval(interval)
        }
      }, 100)
    }

    const fetchIVRPhoneNumber = (categoryData, geoInfo) => {
      // Get the phone number.
      fetch('https://twilioproxy.elocal.com/api/front_end/ivr_search', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          partner_campaign_ids: metadata.partner_campaign_ids,
          visit_data: {
            href: window.location.href,
            ...categoryData,
            session_id: getSessionId(),
            zip_code: geoInfo ? geoInfo.zip_code : metadata.zip_code,
          }
        })
      })
      .then( response => response.json() )
      .then( response => {
        // Check to see if any of the partner_campaign_ids from metadata are in the response object (as a key).  If so, update the phone number in metadata.
        metadata.partner_campaign_ids.map( id => {
          if(response[id]){
            metadata.phone = formatPhoneNumber(response[id])
          }
        })

        updateContext(geoInfo)
        updateVisitData()
      })
      .catch (err => {
        updateContext(geoInfo)
        updateVisitData()
      })
    }

    const fetchCategoryData = geoInfo => {
      // Get the category tracking data.
      fetch(`https://www.elocal.com/api/marketing/category/${metadata.categoryId}`)
        .then( response => response.json() )
        .then( categoryData => {
          setCategoryData(categoryData)
          fetchIVRPhoneNumber(categoryData, geoInfo)
        })
        .catch( err => {
          fetchIVRPhoneNumber({}, geoInfo)
        })
    }

    const fetchGeoInfo = () => {
      fetch('//freegeoip.app/json/')
        .then(response => response.json())
        .then(geoAPIData => {
          if(geoAPIData) {
            localStorage.setItem('geoInfo', JSON.stringify({
              city: geoAPIData.city,
              region_code: geoAPIData.region_code,
              zip_code: geoAPIData.zip_code,
            }))
            const metro = `${geoAPIData.city ? `${geoAPIData.city}` : ''}${geoAPIData.region_code ? `, ${geoAPIData.region_code}` : ''}`
            document.getElementById('root').setAttribute('data-city', metro)
            document.title = `${metadata.title}${metro ? `, ${metro}` : ''}`
            fetchCategoryData(geoAPIData)
          } else {
            fetchCategoryData()
          }
        })
        .catch( err => {
          fetchCategoryData()
        })
    }

    // Update metadata from qs.
    const partner_campign_id_override = new URLSearchParams(window.location.search).get('partner_campaign_id')
    metadata.partner_campaign_ids = partner_campign_id_override ? [ partner_campign_id_override.toString() ] : metadata.partner_campaign_ids || []

    if(geoInfo) {
      const metro = `${geoInfo.city ? `${geoInfo.city}` : ''}${geoInfo.region_code ? `, ${geoInfo.region_code}` : ''}`
      document.getElementById('root').setAttribute('data-city', metro)
      document.title = `${metadata.title}${metro ? `, ${metro}` : ''}`

      fetchCategoryData(geoInfo)
    } else {
      fetchGeoInfo()
    }
  }, [])

  return (
    <SiteContext.Provider value={[ state, setState ]}>
      {props.children}
    </SiteContext.Provider>
  )
}
