import React from 'react'

import cn from 'classnames'

import { SiteContext } from 'context/site-context'

import './index.scss'

const StickyCTA = () => {
  const [ site ] = React.useContext(SiteContext)
  const [ state, setState ] = React.useState({
    show: false,
  })

  React.useEffect(() => {
    const updateMobileCTAState = () => {
      setState({
        ...state,
        show: window.scrollY > window.innerHeight / 2,
      })
    }
    window.addEventListener('scroll', updateMobileCTAState)
    return () => {
      window.removeEventListener('scroll', updateMobileCTAState)
    }
  }, [state])

  return (
    <div className={cn('sticky-cta-container', {show: state.show} )}>
      <a href={`tel:${site.phone}`} data-delegate="StickyCTA">Call {site.phone}</a>
    </div>
  )
}

export default StickyCTA
