import React from 'react'
import PropTypes from 'prop-types'

import { SiteContext } from 'context/site-context'

import SameDayIcon from 'images/calendar.png'
import CreditCardsIcon from 'images/creditcards.png'
import PriceGuideIcon from 'images/priceguide-calc.png'

import './index.scss'

const renderIconBar = () => {
  return (
    <span className="icons-container">
      <div className="icon-container">
        <img src={SameDayIcon} alt="Same Day Service" />
        <div className="content">
          Same Day<br /><strong>Service</strong>
        </div>
      </div>
      <div className="icon-container">
        <img src={CreditCardsIcon} alt="Credit Cards Accepted" />
        <div className="content">
          Credit Cards<br /><strong>Accepted</strong>
        </div>
      </div>
    </span>
  )
}

const PriceGuide = props => {
  const [ site ] = React.useContext(SiteContext)

  const trackingData = {
    page: window.location.href,
    category: 'OPTION',
  }

  return (
    <div className="price-guide-container">
      <div className="container">
        <ul>
          {props.type === 'narrow' && <li className="desktop">{renderIconBar()}</li>}
          <li className="header">
            <div className="icon-container">
              <img src={PriceGuideIcon} alt="Price Guide" />
              <div className="content">
                Price <strong>Guide</strong>
              </div>
            </div>
            {props.type === 'wide' && <span className="desktop wide">{renderIconBar()}</span>}
            <div className="title"><span>min to max</span> <span>price</span></div>
          </li>
          {props.priceList.map( (item, i) => <li key={i}><a href={`tel:${site.phone}`} data-delegate="Price Guide" data-tracking-data={JSON.stringify({ ...trackingData, 'cost-range': item.price })}><label>{item.label}</label><span className="category">{item.category}</span><span className="price">{item.price}</span></a></li>)}
          <li className="disclaimer">* Prices Above Include Base $29 Trip Charge</li>
          <li className="disclaimer">** All prices are for basic service if something else will need to be done on site, tech will provide the prices.</li>
          <li className="disclaimer">*** Cost is a minimum estimate for the service. The final cost can vary.  It will depend on the security level of the lock in addition to labor charges.</li>
          <li className="disclaimer">**** Cost is a minimum estimate for the service. Every key or ignition is differently built and cut, so the final cost can vary. It will depend on the cut of the key and programming cost. If required the tech will provided the prices on site before (s)he will start the job.</li>
          <li className="disclaimer">***** Price guide based on average national pricing</li>
          <li className="icons-container mobile">
            <div className="icon-container">
              <img src={SameDayIcon} alt="Same Day Service" />
              <div className="content">
                Same Day<br /><strong>Service</strong>
              </div>
            </div>
            <div className="icon-container">
              <img src={CreditCardsIcon} alt="Credit Cards Accepted" />
              <div className="content">
                Credit Cards<br /><strong>Accepted</strong>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

PriceGuide.propTypes = {
  priceList: PropTypes.array,
  type: PropTypes.oneOf(['narrow', 'wide']),
}

PriceGuide.defaultProps = {
  priceList: [],
  type: 'wide',
}

export default PriceGuide
