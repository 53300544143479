import React from 'react'

import { SiteContext } from 'context/site-context'

import PhoneCTAButton from 'components/atoms/PhoneCTAButton'

import BackgroundImage from 'images/locksmith-hero.jpg'

import Logo from 'images/logo.png'

import './index.scss'

const HeroLanding = () => {
  const [ site, setSite  ] = React.useContext(SiteContext)

  return (
    <div className="hero-landing-container" style={{
        background: `linear-gradient( rgba(44, 43, 91, .6), rgba(2, 2, 13, .85) ), url(${BackgroundImage}) center center no-repeat`,
        backgroundSize: 'cover',
      }}>
      <div className="container">
        <div className="logo-white-container"><img src={Logo} alt="Locksmith Directory" /></div>
        <div className="actions-container">
          <PhoneCTAButton delegate="Header" />
          <span
            className="button-trigger alt toggle-trigger"
            onClick={ () => {
              setSite({ ...site, viewOverride: 'emergency'})
              window.scrollTo(0, 0)
            } }>Emergency<br/><span className="desktop">Lockout </span>Services</span>
        </div>
        <h1>Get Professional Locksmith Services, Anytime, Anywhere!</h1>
        <h2>Dispatched in Under Thirty Minutes</h2>
      </div>
    </div>
  )
}

export default HeroLanding
