import React from 'react'

import { getTrackingData } from 'helpers'

import { NavLink } from 'react-router-dom'

import { SiteContext } from 'context/site-context'

import StickyCTA from 'components/atoms/StickyCTA'

import LogoIcon from 'images/logo-white.png'

import './index.scss'

const Footer = () => {
  const [ site, setSite ] = React.useContext(SiteContext)

  return (
    <footer>
      <div className="container">
        <div className="logo-container">
          <NavLink to={`${process.env.PUBLIC_URL}/`} className="main-logo" onClick={ () => setSite({ ...site, showHeader: false }) }><img src={LogoIcon} alt="Locksmith Directory" /></NavLink>
        </div>
        <div className="copyright-container">
          &copy; {new Date().getFullYear()} {site.domain}. All Rights Reserved.
        </div>
        <a className="phone-trigger" href={`tel:${site.phone}`} data-delegate="All Rights Reserved" data-tracking-data={getTrackingData()}>{site.phone}</a>
        <div className="support-container desktop">Support: 1-888-250-9157</div>
        <div className="support-container mobile">Support: 1-888-250-9157</div>
        <div className="address-container desktop">eLocal, LLC East Hector Street, Suite 101 Conshohocken, PA 19428</div>
        <div className="nav-bar">
          <a href="https://www.elocal.com/information#terms" data-delegate="Footer">Terms and Conditions</a>
          <a href="https://www.elocal.com/information#privacy" data-delegate="Footer">Privacy Policy</a>
        </div>
      </div>
      <StickyCTA />
    </footer>
  )
}

export default Footer
