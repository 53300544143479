import React from 'react'
import { createRoot } from 'react-dom/client'

import { BrowserRouter } from 'react-router-dom'
import { SiteContextProvider } from './context/site-context'

import { createBrowserHistory } from 'history'

import ScrollToTop from 'components/atoms/ScrollToTop'

import App from './App'

import './index.scss'

import METADATA from 'context/metadata'

document.getElementById('root').setAttribute('data-category-id', METADATA.categoryId || '')
document.getElementById('root').setAttribute('data-site-name', METADATA.siteName)
document.getElementById('root').setAttribute('data-template-id', METADATA.templateId || 'none')

const root = createRoot(document.getElementById('root'))

root.render(
  <SiteContextProvider>
    <BrowserRouter baseUrl={process.env.PUBLIC_URL}>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </BrowserRouter>
  </SiteContextProvider>
)
