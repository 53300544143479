module.exports = {
  categoryId: '173',
  category: 'home_services',
  categoryPlural: 'Locksmiths',
  domain: 'LocksmithDirectory.com',
  partner_campaign_ids: [ '44915' ],
  phone: '888-601-1784',
  prodUrl: 'https://find.locksmithdirectory.com/',
  siteName: 'locksmithdirectory',
  stageUrl: 'https://find.stage.locksmithdirectory.com/',
  templateId: 'RT15',
  title: 'Locksmiths - Locksmith Services and Professional Locksmiths',
}
