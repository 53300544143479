import React from 'react'

import { SiteContext } from 'context/site-context'

import { NavLink } from 'react-router-dom'

import PhoneCTAButton from 'components/atoms/PhoneCTAButton'

import Logo from 'images/logo.png'

import './index.scss'

const HeroService = props => {
  const [ site, setSite ] = React.useContext(SiteContext)

  return (
    <div className="hero-service-container" style={{
        background: `linear-gradient( rgba(44, 43, 91, .6), rgba(2, 2, 13, .85) ), url(${props.backgroundImage}) center center no-repeat`,
        backgroundSize: 'cover',
      }}>
      <div className="container">
        <div className="logo-white-container"><NavLink to={`${process.env.PUBLIC_URL}/`} onClick={ () => setSite({ ...site, showHeader: false }) }><img src={Logo} alt="Locksmith Directory" /></NavLink></div>
          <div className="actions-container">
            <PhoneCTAButton delegate="Header" />
            <NavLink
              to={`${process.env.PUBLIC_URL}/`}
              className="button-trigger alt toggle-trigger"
              onClick={ () => {
                setSite({
                  ...site,
                  showHeader: false,
                  viewOverride: 'emergency',
                })
              } }>Emergency<br/><span className="desktop">Lockout </span>Services</NavLink>
          </div>
        <h1>{props.headline}</h1>
      </div>
    </div>
  )
}

export default HeroService
