import React from 'react'

import cn from 'classnames'

import { SiteContext } from 'context/site-context'

import { NavLink } from 'react-router-dom'

import { ReactComponent as AutomotiveIcon } from 'svgs/automotive.svg'
import { ReactComponent as CommercialIcon } from 'svgs/commercial.svg'
import { ReactComponent as NewHomeIcon } from 'svgs/new-home.svg'
import { ReactComponent as ResidentialIcon } from 'svgs/residential.svg'

import { ReactComponent as CloseIcon } from 'svgs/close.svg'

import PhoneCTAButton from 'components/atoms/PhoneCTAButton'

import './index.scss'

const Modal = props => {
  const [ site, setSite ] = React.useContext(SiteContext)

  const [ state, setState ] = React.useState({
    showModal: false,
    modalShown: false, //localStorage.getItem('modalShown'),
  })

  React.useEffect(() => {
    const updateModalState = () => {
      // localStorage.setItem('modalShown', true)
      if(!site.modalShown){
        setState({
          ...state,
          showModal: true,
        })
        setSite({
          ...site,
          modalShown: true,
        })
        document.body.classList.add('fixed')
      }
    }
    const modalTimeout = setTimeout( () => updateModalState(), 5000 )
    return () => {
      clearTimeout(modalTimeout)
    }
  }, [state, site, setSite])

  const handleModelClose = () => {
    document.body.classList.remove('fixed')
    setState({
      ...state,
      showModal: false,
    })
  }

  return (
    <div className={ cn('modal-container', {shown: state.showModal}) }>
      <div className="overlay"></div>
      <div className="modal">
        <span className="close" onClick={ () => handleModelClose() }><CloseIcon /></span>
        <p>Already know what you're looking for? </p>
        <h1>Call a Locksmith Now</h1>
        <PhoneCTAButton delegate="Pop Up" />
        <div className="icons-container">
          <NavLink
            to={`${process.env.PUBLIC_URL}/ld-residential`}
            className="panel"
            data-tracking-data={JSON.stringify({ label: 'Pop Up - Residential Locksmiths' })}
            onClick={ () => {
              handleModelClose()
              setSite({
                ...site,
                showHeader: false,
                viewOverride: 'non-emergency',
              })
            }}>
            <ResidentialIcon />
            <aside>Residential<br />Locksmiths</aside>
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/ld-commercial`}
            className="panel"
            data-tracking-data={JSON.stringify({ label: 'Pop Up - Commercial Locksmiths' })}
            onClick={ () => {
              handleModelClose()
              setSite({
                ...site,
                showHeader: false,
                viewOverride: 'non-emergency',
              })
            }}>
            <CommercialIcon />
            <aside>Commercial<br />Locksmiths</aside>
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/ld-automotive`}
            className="panel"
            data-tracking-data={JSON.stringify({ label: 'Pop Up - Automotive Locksmiths' })}
            onClick={ () => {
              handleModelClose()
              setSite({
                ...site,
                showHeader: false,
                viewOverride: 'non-emergency',
              })
            }}>
            <AutomotiveIcon />
            <aside>Automotive<br />Locksmiths</aside>
          </NavLink>
          <NavLink
            to={`${process.env.PUBLIC_URL}/ld-new-home`}
            className="panel"
            data-tracking-data={JSON.stringify({ label: 'Pop Up - New Homeowner Locksmiths' })}
            onClick={ () => {
              handleModelClose()
              setSite({
                ...site,
                showHeader: false,
                viewOverride: 'non-emergency',
              })
            }}>
            <NewHomeIcon />
            <aside>New Homeowner<br />Locksmiths</aside>
          </NavLink>
        </div>
        <div className="disclaimer">
          This is a dispatch service to help consumers find prompt locksmith service. LocksmithDirectory.com is an advertising service and does not employ the contractors that use our service.
        </div>
      </div>
    </div>
  )
}

export default Modal
